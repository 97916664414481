<template>
  <div>
    <v-card flat>
      <v-card-text class="pa-0">
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            mb-3
            pb-2
          >
            <tooltip
              :name="
                isMultipleOptionsEnabled
                  ? 'RegistrationQuestionOptionsMultiple'
                  : 'RegistrationQuestionOptions'
              "
            >
              <strong>{{ $t("options") }}</strong>
            </tooltip>
          </v-flex>
          <v-flex
            xs12
            v-show="!isMultipleOptionsEnabled"
            class="mb-4"
          >
            <draggable
              v-model="model.options"
              :handle="'.handle'"
            >
              <div
                v-for="(option, i) in model.options"
                :key="`option-${i}`"
              >
                <v-layout
                  row
                  nowrap
                  class="align-items-center"
                >
                  <div
                    v-if="model.options.length > 1"
                    class="handle pr-3"
                  >
                    <font-awesome-icon
                      icon="grip-vertical"
                      class="handle sw-accent"
                    />
                  </div>
                  <v-flex
                    xs12
                    class="pr-2"
                  >
                    <v-text-field
                      v-model="option.name"
                      :error-messages="errors.collect(`option_${i}.name`)"
                      v-validate.disable="'required|max:150'"
                      :data-vv-name="`option_${i}.name`"
                      :data-vv-as="
                        $tc('signup_wizard.questions_option_name_label', 2)
                      "
                      :label="
                        $t('signup_wizard.questions_option_name_placeholder')
                      "
                      autofocus
                      @keyup.enter.native="addNewOption"
                    />
                  </v-flex>
                  <v-btn
                    icon
                    class="mr-0"
                    @click="editAttributeOption(i, option)"
                  >
                    <v-icon
                      class="sw-accent"
                      small
                      >edit</v-icon
                    >
                  </v-btn>
                  <div v-if="model.options.length > 1">
                    <v-btn
                      v-if="option.editable"
                      icon
                      class="mr-0"
                      @click="removeOption(i)"
                    >
                      <font-awesome-icon
                        class="sw-accent"
                        icon="trash"
                      />
                    </v-btn>
                  </div>
                </v-layout>
              </div>
            </draggable>
          </v-flex>
          <v-flex
            xs12
            v-show="isMultipleOptionsEnabled"
          >
            <v-textarea
              v-model="multipleOptions"
              :placeholder="$t('signup_wizard.insert_text_option_per_line')"
              solo
              rows="10"
              no-resize
              ref="multiple-option"
            >
            </v-textarea>
          </v-flex>
          <v-flex xs12>
            <v-layout>
              <template v-if="!isMultipleOptionsEnabled">
                <v-btn
                  round
                  class="mx-0 sw-secondary-bg sw-on-secondary text-none"
                  @click="addNewOption"
                  >{{ $t("new-option") }}</v-btn
                >
                <tooltip name="RegistrationQuestionOptionsMultiple">
                  <v-btn
                    round
                    class="mr-0 ml-3 sw-secondary-bg sw-on-secondary text-none"
                    @click="addMultipleOptions"
                    >{{ $t("signup_wizard.add_multiple_options") }}</v-btn
                  >
                </tooltip>
              </template>
              <template v-if="isMultipleOptionsEnabled">
                <v-btn
                  round
                  class="mx-0 sw-secondary-bg sw-on-secondary text-none"
                  @click="isMultipleOptionsEnabled = false"
                  >{{ $t("common.cancel") }}</v-btn
                >
                <v-btn
                  round
                  class="mr-0 ml-3 sw-secondary-bg sw-on-secondary text-none"
                  :disabled="!multipleOptions || !multipleOptions.length"
                  @click="addOptions"
                  >{{ $t("signup_wizard.add_options") }}</v-btn
                >
              </template>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <AttributesOptionsOptionModal
      ref="optionModal"
      :display-type="displayType"
      @update="updateUserAttributeValue"
    />
  </div>
</template>

<script>
import AttributesOptionsOptionModal from "@/components/SignupWizard/SignupQuestions/AttributesOptionsOptionModal.vue";
import draggable from "vuedraggable";

export default {
  inject: ["parentValidator"],
  props: {
    value: {
      type: Object,
      default: () => ({
        options: [],
      }),
    },
    displayType: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    multipleOptions: null,
    isMultipleOptionsEnabled: false,
  }),
  computed: {
    activeGroup() {
      return this.$store.getters.activeGroup;
    },
    defaultTaxRate() {
      return this.activeGroup && this.activeGroup.default_tax_rate
        ? this.activeGroup.default_tax_rate
        : null;
    },
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  components: {
    AttributesOptionsOptionModal,
    draggable,
  },
  watch: {
    "model.options": {
      handler(val) {
        if (val.length === 0) {
          this.addNewOption();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    addNewOption(option) {
      const defaultOption = {
        name: null,
        body: "",
        value: null,
        usage_limit: null,
        price: null,
        vat: this.defaultTaxRate ? this.defaultTaxRate : null,
        editable: true,
      };
      this.model.options.push({ ...defaultOption, ...option });
    },
    addOptions() {
      this.multipleOptions.split("\n").forEach((name) => {
        if (!name) return;

        const option = name.split(";");

        this.addNewOption({
          name: option[0],
          value: option[0],
          usage_limit: option[1] || null,
          price: option[2] || null,
        });
      });

      this.isMultipleOptionsEnabled = false;
    },
    addMultipleOptions() {
      this.multipleOptions = null;
      this.isMultipleOptionsEnabled = true;

      if (this.isMultipleOptionsEnabled) {
        this.$nextTick(() => this.$refs["multiple-option"].focus());
      }
    },
    removeOption(index) {
      this.model.options.splice(index, 1);
    },
    editAttributeOption(optionIndex, option) {
      this.$refs.optionModal.open(optionIndex, option);
    },
    updateUserAttributeValue(optionIndex, option) {
      this.$set(this.model.options, optionIndex, option);
    },
  },
};
</script>

<style scoped>
.handle {
  cursor: grab;
}

.sortable-chosen {
  opacity: 0.4;
}
</style>
