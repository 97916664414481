<template>
  <div class="signup-questions">
    <div
      v-if="isMigrationRequired"
      class="error--text caption"
    >
      {{ $t("signup_wizard.question_migration_required_message") }}
    </div>

    <v-form
      data-vv-scope="questions"
      :class="{ loading: isLoading }"
    >
      <v-layout class="row nowrap mb-4">
        <v-flex xs3>
          <v-switch
            v-model="plugin.signup_adminstration_attribute_tags"
            class="row"
            :disabled="actionsLocked"
            hide-details
          >
            <template slot="label">
              <div class="black--text">
                {{
                  $t("signup_wizard.signup_adminstration_attribute_tag_label")
                }}
              </div>
            </template>
          </v-switch>
        </v-flex>
        <v-flex>
          <v-switch
            v-model="plugin.signup_guests_attribute_tags"
            :disabled="actionsLocked"
            hide-details
          >
            <template slot="label">
              <div class="black--text">
                {{ $t("signup_wizard.signup_guests_attribute_tag_label") }}
              </div>
            </template>
          </v-switch>
        </v-flex>
      </v-layout>

      <v-layout
        v-if="!plugin.signup_adminstration_attribute_tags"
        class="row wrap"
      >
        <v-flex
          xs12
          class="mb-4"
        >
          <div
            v-for="(tag, tagIndex) in attributeTagsMapped"
            :key="`tag-${tagIndex}`"
          >
            <draggable
              v-model="tag.items"
              :handle="'.draggable-handle'"
              @change="sortAttributes"
            >
              <div
                v-for="(attribute, attributeIndex) in tag.items"
                :key="'attribute-' + attributeIndex"
                class="mb-3"
              >
                <v-expansion-panel>
                  <v-expansion-panel-content>
                    <template slot="header">
                      <v-layout class="row no-wrap align-items-center">
                        <v-flex class="xs12 attribute-name">
                          <v-layout
                            row
                            class="align-center"
                          >
                            <font-awesome-icon
                              icon="grip-vertical"
                              class="sw-accent draggable-handle mr-3"
                              :class="{
                                disabled: !attributesSortEnabled,
                              }"
                            />
                            <v-flex class="sw-h5 font-weight-light"
                              >{{ attribute.name }}
                              <div
                                v-if="attribute.description"
                                class="caption grey--text"
                              >
                                {{ attribute.description }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <div class="mr-3">
                          <v-tooltip top>
                            <v-btn
                              slot="activator"
                              icon
                              class="my-0 mr-0"
                              @click.stop="
                                attribute.hidden = !attribute.hidden;
                                updateUserAttribute(attribute);
                              "
                            >
                              <font-awesome-icon
                                :icon="attribute.hidden ? 'eye-slash' : 'eye'"
                                :class="
                                  attribute.hidden ? 'grey--text' : 'sw-accent'
                                "
                              />
                            </v-btn>
                            <span
                              v-html="
                                $t('signup_wizard.question_visibility_tooltip')
                              "
                            ></span>
                          </v-tooltip>
                          <v-tooltip top>
                            <v-btn
                              slot="activator"
                              icon
                              class="my-0 mr-0"
                              @click.stop="
                                showAttributeDialog(tag.id, attribute.id)
                              "
                            >
                              <v-icon
                                color="grey"
                                small
                                >edit</v-icon
                              >
                            </v-btn>
                            <span>{{ $t("edit") }}</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <v-btn
                              slot="activator"
                              icon
                              :class="'my-0 mr-0'"
                              @click.stop="showRelations(attribute)"
                            >
                              <font-awesome-icon
                                color="gray"
                                :class="
                                  attribute.relation ? 'relation__icon' : ''
                                "
                                icon="code-branch"
                              />
                            </v-btn>
                            <span>{{ $t("relations") }}</span>
                          </v-tooltip>
                        </div>
                      </v-layout>
                    </template>
                    <v-card
                      color="grey lighten-5"
                      class="pa-2"
                    >
                      <template
                        v-if="
                          attribute.details && attribute.details.description
                        "
                      >
                        <div
                          v-html="attribute.details.description"
                          class="sw-content-typography pt-2 pb-4 clearfix"
                        ></div>
                      </template>
                      <div
                        v-if="
                          attribute.type === 'options' &&
                          attribute.is_collection
                        "
                      >
                        <div
                          v-for="(option, optionIndex) in attribute.options"
                          :key="`option-${optionIndex}`"
                        >
                          <v-checkbox
                            :label="formatOptionLabel(option)"
                            hide-details
                            class="shrink mt-0"
                            disabled
                          />
                        </div>
                        <div v-if="!attribute || !attribute.options[0]">
                          {{ $t("no-options-added-yet") }}!
                        </div>
                      </div>
                      <div
                        v-if="
                          attribute.type === 'options' &&
                          !attribute.is_collection
                        "
                      >
                        <v-radio-group column>
                          <template
                            v-for="(option, optionIndex) in attribute.options"
                          >
                            <v-radio
                              :key="`option-${optionIndex}`"
                              :label="formatOptionLabel(option)"
                              hide-details
                              disabled
                            />
                          </template>
                        </v-radio-group>
                        <div v-if="!attribute.options.length">
                          {{ $t("no-options-added-yet") }}!
                        </div>
                      </div>
                      <div v-if="attribute.type === 'varchar'">
                        <v-text-field
                          :placeholder="$t('textFieldPlaceholder')"
                          hide-details
                          solo
                          disabled
                        />
                      </div>
                      <div v-if="attribute.type === 'text'">
                        <v-textarea
                          :placeholder="$t('textFieldPlaceholder')"
                          no-resize
                          hide-details
                          solo
                          disabled
                        />
                      </div>
                      <div v-if="attribute.type === 'datetime'">
                        <v-text-field
                          :placeholder="'DD.MM.YYYY'"
                          hide-details
                          solo
                          disabled
                        />
                      </div>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </div>
            </draggable>
          </div>
        </v-flex>
        <v-flex
          xs12
          class="text-center"
        >
          <v-btn
            fab
            large
            class="sw-accent-bg sw-on-accent"
            @click="showAttributeDialog()"
            :disabled="attributeTags.length > 1 || isMigrationRequired"
          >
            <v-icon dark>add</v-icon>
          </v-btn>
          <div class="caption">
            {{ $t("signup_wizard.add_attribute_btn_text") }}
          </div>
        </v-flex>
      </v-layout>

      <v-layout
        v-if="plugin.signup_adminstration_attribute_tags"
        class="row wrap"
      >
        <v-flex
          xs12
          class="mb-4"
        >
          <draggable
            v-model="attributeTagsMapped"
            :group="{ name: 'tag', pull: false, put: false }"
            :handle="'.draggable-handle'"
            @change="sortAttributeTags"
          >
            <div
              v-for="(tag, tagIndex) in attributeTagsMapped"
              :key="`tag-${tagIndex}`"
              class="mb-3"
            >
              <v-expansion-panel :readonly="actionsLocked">
                <v-expansion-panel-content>
                  <template v-slot:header>
                    <v-layout class="row nowrap align-items-center">
                      <font-awesome-icon
                        icon="grip-vertical"
                        class="sw-accent draggable-handle mr-3"
                        :class="{
                          disabled: !attributeTagsSortEnabled || actionsLocked,
                        }"
                      />
                      <v-layout class="row wrap">
                        <v-flex xs12>
                          <v-layout class="row nowrap align-items-center">
                            <v-layout
                              class="row nowrap align-items-center"
                              v-show="
                                attributeTagNameEditId !== tag.id &&
                                !errors.collect(`questions.name_${tagIndex}`)
                                  .length
                              "
                            >
                              <span
                                class="sw-h5"
                                @click.stop="
                                  editAttributeTagName(tag.id, tagIndex)
                                "
                                >{{ tag.name }}</span
                              >
                              <span class="tag-controls ml-3">
                                <v-tooltip
                                  top
                                  :disabled="actionsLocked"
                                >
                                  <v-btn
                                    slot="activator"
                                    icon
                                    small
                                    class="ma-0"
                                    @click.stop="
                                      tag.hide_name = !tag.hide_name;
                                      attributeTagUpdate(tag);
                                    "
                                    :disabled="actionsLocked"
                                  >
                                    <font-awesome-icon
                                      :icon="
                                        tag.hide_name ? 'eye-slash' : 'eye'
                                      "
                                      :class="
                                        actionsLocked || tag.hide_name
                                          ? 'grey--text'
                                          : 'sw-accent'
                                      "
                                    />
                                  </v-btn>
                                  <span>{{
                                    $t("signup_wizard.hide_name_tooltip")
                                  }}</span>
                                </v-tooltip>
                              </span>
                            </v-layout>
                            <v-layout
                              v-show="
                                attributeTagNameEditId === tag.id ||
                                errors.collect(`questions.name_${tagIndex}`)
                                  .length
                              "
                              row
                              wrap
                            >
                              <v-flex xs12>
                                <v-text-field
                                  ref="attributeTagNameRef"
                                  v-model="tag.name"
                                  maxlength="255"
                                  :label="$t('signup_wizard.tag_name_label')"
                                  hide-details
                                  @click.stop
                                  @blur="attributeTagUpdate(tag)"
                                  v-validate.disable="'required'"
                                  :data-vv-name="`name_${tagIndex}`"
                                  :data-vv-as="
                                    $t('signup_wizard.tag_name_label')
                                  "
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12>
                                <ErrorMessages
                                  :error-messages="
                                    errors.collect(`questions.name_${tagIndex}`)
                                  "
                                />
                              </v-flex>
                            </v-layout>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout class="row nowrap align-items-center">
                            <v-layout
                              class="row nowrap align-items-center"
                              v-show="
                                attributeTagDescriptionEditId !== tag.id &&
                                !errors.collect(
                                  `questions.description_${tagIndex}`,
                                ).length
                              "
                            >
                              <span
                                @click.stop="
                                  editAttributeTagDescription(tag.id, tagIndex)
                                "
                                >{{ tag.description }}</span
                              >
                              <span class="tag-controls ml-3">
                                <v-tooltip
                                  top
                                  :disabled="actionsLocked"
                                >
                                  <v-btn
                                    slot="activator"
                                    icon
                                    small
                                    class="ma-0"
                                    @click.stop="
                                      tag.hide_description =
                                        !tag.hide_description;
                                      attributeTagUpdate(tag);
                                    "
                                    :disabled="actionsLocked"
                                  >
                                    <font-awesome-icon
                                      :icon="
                                        tag.hide_description
                                          ? 'eye-slash'
                                          : 'eye'
                                      "
                                      :class="
                                        actionsLocked || tag.hide_description
                                          ? 'grey--text'
                                          : 'sw-accent'
                                      "
                                    />
                                  </v-btn>
                                  <span>{{
                                    $t("signup_wizard.hide_description_tooltip")
                                  }}</span>
                                </v-tooltip>
                              </span>
                            </v-layout>
                            <v-layout
                              v-show="
                                attributeTagDescriptionEditId === tag.id ||
                                errors.collect(
                                  `questions.description_${tagIndex}`,
                                ).length
                              "
                              row
                              wrap
                            >
                              <v-flex xs12>
                                <v-text-field
                                  ref="attributeTagDescriptionRef"
                                  v-model="tag.description"
                                  maxlength="255"
                                  :label="
                                    $t('signup_wizard.tag_description_label')
                                  "
                                  hide-details
                                  @click.stop
                                  @blur="attributeTagUpdate(tag)"
                                  v-validate.disable="'required'"
                                  :data-vv-name="`description_${tagIndex}`"
                                  :data-vv-as="
                                    $t('signup_wizard.tag_description_label')
                                  "
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12>
                                <ErrorMessages
                                  :error-messages="
                                    errors.collect(
                                      `questions.description_${tagIndex}`,
                                    )
                                  "
                                />
                              </v-flex>
                            </v-layout>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-btn
                        slot="activator"
                        icon
                        class="my-0 mr-3"
                        @click.stop="openDeleteConfirmationDialog(tag)"
                        :disabled="actionsLocked"
                      >
                        <font-awesome-icon
                          :class="actionsLocked ? 'grey--text' : 'sw-accent'"
                          icon="trash"
                        />
                      </v-btn>
                    </v-layout>
                  </template>
                  <v-card
                    color="grey lighten-5"
                    class="pa-3"
                  >
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <draggable
                          v-model="tag.items"
                          :group="{ name: 'tag', pull: true, put: true }"
                          :handle="'.draggable-handle'"
                          @change="sortAttributes"
                        >
                          <div
                            v-for="(attribute, attributeIndex) in tag.items"
                            :key="'attribute-' + attributeIndex"
                            class="mb-3"
                          >
                            <v-expansion-panel :readonly="actionsLocked">
                              <v-expansion-panel-content>
                                <template slot="header">
                                  <v-layout
                                    class="row no-wrap align-items-center"
                                  >
                                    <v-flex class="xs12 attribute-name">
                                      <v-layout
                                        row
                                        class="align-center"
                                      >
                                        <font-awesome-icon
                                          icon="grip-vertical"
                                          class="sw-accent draggable-handle mr-3"
                                          :class="{
                                            disabled:
                                              !attributesSortEnabled ||
                                              actionsLocked,
                                          }"
                                        />
                                        <v-flex class="sw-h5 font-weight-light"
                                          >{{ attribute.name }}
                                          <div
                                            v-if="attribute.description"
                                            class="caption grey--text"
                                          >
                                            {{ attribute.description }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <div class="mr-3">
                                      <v-tooltip
                                        top
                                        :disabled="actionsLocked"
                                      >
                                        <v-btn
                                          slot="activator"
                                          icon
                                          class="my-0 mr-0"
                                          @click.stop="
                                            attribute.hidden =
                                              !attribute.hidden;
                                            updateUserAttribute(attribute);
                                          "
                                        >
                                          <font-awesome-icon
                                            :icon="
                                              attribute.hidden
                                                ? 'eye-slash'
                                                : 'eye'
                                            "
                                            :class="
                                              attribute.hidden
                                                ? 'grey--text'
                                                : 'sw-accent'
                                            "
                                          />
                                        </v-btn>
                                        <span
                                          v-html="
                                            $t(
                                              'signup_wizard.question_visibility_tooltip',
                                            )
                                          "
                                        ></span>
                                      </v-tooltip>
                                      <v-tooltip
                                        top
                                        :disabled="actionsLocked"
                                      >
                                        <v-btn
                                          slot="activator"
                                          icon
                                          class="my-0 mr-0"
                                          @click.stop="
                                            showAttributeDialog(
                                              tag.id,
                                              attribute.id,
                                            )
                                          "
                                          :disabled="actionsLocked"
                                        >
                                          <v-icon
                                            color="grey"
                                            small
                                            >edit</v-icon
                                          >
                                        </v-btn>
                                        <span>{{ $t("edit") }}</span>
                                      </v-tooltip>
                                      <v-tooltip
                                        top
                                        :disabled="actionsLocked"
                                      >
                                        <v-btn
                                          slot="activator"
                                          icon
                                          :class="'my-0 mr-0'"
                                          @click.stop="showRelations(attribute)"
                                          :disabled="actionsLocked"
                                        >
                                          <font-awesome-icon
                                            color="gray"
                                            :class="
                                              attribute.relation
                                                ? 'relation__icon'
                                                : ''
                                            "
                                            icon="code-branch"
                                          />
                                        </v-btn>
                                        <span>{{ $t("relations") }}</span>
                                      </v-tooltip>
                                    </div>
                                  </v-layout>
                                </template>
                                <v-card
                                  color="grey lighten-5"
                                  class="pa-2"
                                >
                                  <template
                                    v-if="
                                      attribute.details &&
                                      attribute.details.description
                                    "
                                  >
                                    <div
                                      v-html="attribute.details.description"
                                      class="sw-content-typography pt-2 pb-4 clearfix"
                                    ></div>
                                  </template>
                                  <div
                                    v-if="
                                      attribute.type === 'options' &&
                                      attribute.is_collection
                                    "
                                  >
                                    <div
                                      v-for="(
                                        option, optionIndex
                                      ) in attribute.options"
                                      :key="`option-${optionIndex}`"
                                    >
                                      <v-checkbox
                                        :label="formatOptionLabel(option)"
                                        hide-details
                                        class="shrink mt-0"
                                        disabled
                                      />
                                    </div>
                                    <div
                                      v-if="!attribute || !attribute.options[0]"
                                    >
                                      {{ $t("no-options-added-yet") }}!
                                    </div>
                                  </div>
                                  <div
                                    v-if="
                                      attribute.type === 'options' &&
                                      !attribute.is_collection
                                    "
                                  >
                                    <v-radio-group column>
                                      <template
                                        v-for="(
                                          option, optionIndex
                                        ) in attribute.options"
                                      >
                                        <v-radio
                                          :key="`option-${optionIndex}`"
                                          :label="formatOptionLabel(option)"
                                          hide-details
                                          disabled
                                        />
                                      </template>
                                    </v-radio-group>
                                    <div v-if="!attribute.options.length">
                                      {{ $t("no-options-added-yet") }}!
                                    </div>
                                  </div>
                                  <div v-if="attribute.type === 'varchar'">
                                    <v-text-field
                                      :placeholder="$t('textFieldPlaceholder')"
                                      hide-details
                                      solo
                                      disabled
                                    />
                                  </div>
                                  <div v-if="attribute.type === 'text'">
                                    <v-textarea
                                      :placeholder="$t('textFieldPlaceholder')"
                                      no-resize
                                      hide-details
                                      solo
                                      disabled
                                    />
                                  </div>
                                  <div v-if="attribute.type === 'datetime'">
                                    <v-text-field
                                      :placeholder="'DD.MM.YYYY'"
                                      hide-details
                                      solo
                                      disabled
                                    />
                                  </div>
                                </v-card>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </div>
                        </draggable>
                      </v-flex>
                      <v-flex
                        xs12
                        class="text-center"
                      >
                        <v-btn
                          fab
                          small
                          class="sw-accent-bg sw-on-accent"
                          @click="showAttributeDialog(tag.id)"
                          :disabled="actionsLocked"
                        >
                          <v-icon dark>add</v-icon>
                        </v-btn>
                        <div class="caption">
                          {{ $t("signup_wizard.add_attribute_btn_text") }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </div>
          </draggable>
        </v-flex>
        <v-flex
          xs12
          class="text-center"
        >
          <v-btn
            fab
            large
            class="sw-accent-bg sw-on-accent"
            @click="attributeTagCreate()"
            :disabled="actionsLocked"
          >
            <v-icon dark>add</v-icon>
          </v-btn>
          <div class="caption">
            {{ $t("signup_wizard.add_attribute_tag_btn_text") }}
          </div>
        </v-flex>
      </v-layout>

      <v-layout
        row
        wrap
      >
        <v-flex
          v-if="errors && errors.collect('questions.questions').length"
          xs12
        >
          <ErrorMessages
            :error-messages="errors.collect('questions.questions')"
          />
        </v-flex>
      </v-layout>
    </v-form>

    <AttributeTagDeleteConfirmationDialog
      :loading="isLoading"
      ref="attributeTagDeleteConfirmationDialogRef"
      @confirm="attributeTagDelete(attributeTagToDelete.id)"
    />

    <Attributes
      v-if="category.id"
      :groupId="groupId"
      :groupPluginId="plugin.id"
      :categoryId="category.id"
      ref="attributeRef"
      @attribute-created="atttributeListSort"
      @attribute-updated="atttributeList"
    />

    <Relations
      v-if="category.id"
      :groupId="groupId"
      :groupPluginId="plugin.id"
      :categoryId="category.id"
      ref="relationsRef"
      @update-relation="atttributeList"
      @delete-relation="atttributeList"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import AttributeTagDeleteConfirmationDialog from "@/components/SignupWizard/SignupQuestions/AttributeTagDeleteConfirmationDialog.vue";
import Attributes from "@/components/SignupWizard/SignupQuestions/Attributes.vue";
import Relations from "@/components/SignupWizard/SignupQuestions/Relations.vue";

export default {
  inject: ["parentValidator"],
  props: {
    groupId: {
      type: [String, Number],
    },
  },
  data: () => ({
    isLoading: false,
    isProcessing: false,
    attributeTagsMapped: [],
    attributeTagToDelete: {},
    attributeTagNameEditId: null,
    attributeTagDescriptionEditId: null,
  }),
  computed: {
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    plugin: {
      get() {
        return this.$store.getters.signupWizardPlugin;
      },
      set(val) {
        this.$store.dispatch("setSignupWizardPlugin", val);
      },
    },
    category() {
      return this.$store.getters.signupWizardCategory;
    },
    attributeTags: {
      get() {
        return this.$store.getters.signupWizardAttributeTags;
      },
      set(val) {
        this.$store.dispatch("setSignupWizardAttributeTags", val);
      },
    },
    attributes: {
      get() {
        return this.$store.getters.signupWizardAttributes;
      },
      set(val) {
        this.$store.dispatch("setSignupWizardAttributes", val);
      },
    },
    isMigrationRequired() {
      return this.attributes.some((el) => !el.tags.length);
    },
    attributeWatcher() {
      return {
        tags: this.attributeTags,
        attributes: this.attributes,
      };
    },
    attributeTagsSortEnabled() {
      if (this.actionsLocked) return;

      return this.attributeTags.length > 1;
    },
    attributesSortEnabled() {
      if (this.actionsLocked) return;

      if (!this.plugin.signup_adminstration_attribute_tags) {
        return this.attributeTags.length === 1 && this.attributes.length > 1;
      }

      return this.attributeTags.length > 1 || this.attributes.length > 1;
    },
    actionsLocked() {
      if (
        !this.attributeTagNameEditId &&
        !this.attributeTagDescriptionEditId &&
        !this.isMigrationRequired
      ) {
        return false;
      }
      return true;
    },
  },
  components: {
    draggable,
    AttributeTagDeleteConfirmationDialog,
    Attributes,
    Relations,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  watch: {
    attributeWatcher: {
      handler(newVal) {
        this.attributeTagsMapped = newVal.tags.map((tag) => ({
          id: tag.id,
          name: tag.name,
          description: tag.description,
          hide_name: tag.hide_name,
          hide_description: tag.hide_description,
          items: newVal.attributes.filter((attribute) =>
            attribute.tags.some((el) => el.id === tag.id),
          ),
        }));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    editAttributeTagName(id, index) {
      if (this.actionsLocked) return;

      this.attributeTagNameEditId = id;
      this.$nextTick(() => this.$refs.attributeTagNameRef[index].focus());
    },
    editAttributeTagDescription(id, index) {
      if (this.actionsLocked) return;

      this.attributeTagDescriptionEditId = id;
      this.$nextTick(() =>
        this.$refs.attributeTagDescriptionRef[index].focus(),
      );
    },
    async attributeTagCreate() {
      try {
        const specs = [
          this.groupId,
          {
            name: "Click here to change group name",
            description: "Click here to change group description",
            hide_name: false,
            hide_description: false,
            group_plugin_id: this.plugin.id,
            lang: this.appContentLanguage,
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupUserAttributeTags.create(
          ...specs,
        );

        await this.atttributeTagListSilent();

        this.isLoading = false;

        if (!this.plugin.signup_adminstration_attribute_tags) return;

        const attributeTag = response.data.data;
        const attributeTagIndex = this.attributeTags.findIndex(
          (tag) => tag.id === attributeTag.id,
        );

        this.editAttributeTagName(attributeTag.id, attributeTagIndex);
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async atttributeListSilent() {
      this.errors.clear();

      const params = [
        this.groupId,
        {
          categories: this.category.id,
          lang: this.appContentLanguage,
          with_reserved: 1,
          per_page: 100,
        },
      ];

      const response = await this.$api.groupUserAttributes.list(...params);

      const attributes = response.data.data.filter(
        (el) =>
          el.enabled && !el.slug.includes(`reserved_g${this.category.id}`),
      );

      await this.$store.dispatch("setSignupWizardAttributes", attributes);
    },
    async atttributeList() {
      try {
        this.isLoading = true;

        await this.atttributeListSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async atttributeTagListSilent() {
      const params = [
        this.groupId,
        { group_plugin_id: this.plugin.id, lang: this.appContentLanguage },
      ];

      const response = await this.$api.groupUserAttributeTags.list(...params);

      this.attributeTags = response.data.data;
    },
    async atttributeListSort() {
      try {
        this.isLoading = true;

        await this.atttributeListSilent();
        await this.sortAttributes();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async attributeTagUpdate(tagModel) {
      const isValid = await this.$validator.validateAll("questions");

      if (!isValid) return;

      try {
        const specs = [
          this.groupId,
          tagModel.id,
          {
            name: tagModel.name,
            description: tagModel.description,
            hide_name: tagModel.hide_name,
            hide_description: tagModel.hide_description,
            lang: this.appContentLanguage,
          },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributeTags.update(...specs);
        await this.atttributeTagListSilent();

        this.isLoading = false;
        this.attributeTagNameEditId = null;
        this.attributeTagDescriptionEditId = null;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    openDeleteConfirmationDialog(model) {
      if (!this.$refs.attributeTagDeleteConfirmationDialogRef) return;

      this.attributeTagToDelete = model;

      const attributeTag = this.attributeTagsMapped.find(
        (el) => el.id === this.attributeTagToDelete.id,
      );

      if (!attributeTag.items.length) {
        this.attributeTagDelete(this.attributeTagToDelete.id);
        return;
      }

      this.$refs.attributeTagDeleteConfirmationDialogRef.openDialog();
    },
    async updateUserAttribute(attribute) {
      try {
        const specs = [
          this.groupId,
          attribute.id,
          {
            hidden: attribute.hidden ? 1 : 0,
          },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributes.update(...specs);

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async attributeTagDelete(id) {
      try {
        this.isLoading = true;

        const attributeTag = this.attributeTagsMapped.find(
          (el) => el.id === id,
        );

        for (
          let attrIndex = 0;
          attrIndex < attributeTag.items.length;
          attrIndex++
        ) {
          const attributeUpdateSpecs = [
            this.groupId,
            attributeTag.items[attrIndex].id,
            {
              enabled: 0,
            },
          ];

          await this.$api.groupUserAttributes.update(...attributeUpdateSpecs);
        }

        const attributeTagDeleteParams = [this.groupId, id];

        await this.$api.groupUserAttributeTags.delete(
          ...attributeTagDeleteParams,
        );
        await this.atttributeTagListSilent();
        await this.atttributeListSilent();

        this.isLoading = false;

        this.attributeTagToDelete = {};
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.attributeTagToDelete = {};
          this.errorMessageShow(error);
        }
      }
    },
    async showAttributeDialog(attributeTagId, attributeId) {
      if (!this.$refs.attributeRef) return;

      if (!this.attributeTags.length) {
        await this.attributeTagCreate();
      }

      const attributeTag = this.attributeTags[0];

      const params = [attributeTagId || attributeTag.id, attributeId];

      this.$refs.attributeRef.showAttributeDialog(...params);
    },
    showRelations(attribute) {
      if (!this.$refs.relationsRef || !attribute) {
        return;
      }
      this.$refs.relationsRef.open(attribute);
    },
    validateAttributeOrder() {
      let attributes = [];

      this.attributeTagsMapped.forEach((tag) => {
        attributes = [...attributes, ...tag.items];
      });

      for (let attrIndex = 0; attrIndex < attributes.length; attrIndex++) {
        if (
          !attributes[attrIndex] ||
          !attributes[attrIndex].relation ||
          !attributes[attrIndex].relation.requirements
        ) {
          continue;
        }

        const requirements = Object.values(
          attributes[attrIndex].relation.requirements,
        );

        for (let reqIndex = 0; reqIndex < requirements.length; reqIndex++) {
          const opts = Object.values(requirements[reqIndex].opts).filter(
            (value, index, array) => array.indexOf(value) === index,
          );

          for (let optIndex = 0; optIndex < opts.length; optIndex++) {
            const relatedAttrIndex = attributes.findIndex(
              (el) => el.id === opts[optIndex],
            );

            if (relatedAttrIndex > attrIndex) {
              const message = this.$t("order-update-failed", {
                questionA: attributes[attrIndex].name,
                questionB: attributes[relatedAttrIndex].name,
              });

              this.$store.dispatch("addErrorNotification", { message });

              return false;
            }
          }
        }
      }

      return true;
    },
    async sortAttributeTags() {
      const isValid = this.validateAttributeOrder();

      if (!isValid) {
        try {
          this.isLoading = true;

          await this.atttributeTagListSilent();

          this.isLoading = false;
        } catch (error) {
          if (error) {
            this.isLoading = false;
            this.errorMessageShow(error);
          }
        }
        return;
      }

      try {
        const specs = [
          this.groupId,
          { new_order: this.attributeTagsMapped.map((tag) => tag.id) },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributeTags.sort(...specs);
        await this.atttributeTagListSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async sortAttributes(node) {
      if (this.isProcessing) return;

      this.isProcessing = true;

      const isValid = this.validateAttributeOrder();

      if (!isValid) {
        await this.atttributeList();

        this.isProcessing = false;
        return;
      }

      try {
        let newOrder = [];

        this.attributeTagsMapped.forEach((tag) => {
          newOrder = [...newOrder, ...tag.items];
        });

        const specs = [
          this.groupId,
          { new_order: newOrder.map((el) => el.id).toString() },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributes.sort(...specs);

        this.isProcessing = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.isProcessing = false;
          this.errorMessageShow(error);
        }
      }

      if (!node || !node.added) {
        await this.atttributeListSilent();

        this.isLoading = false;

        return;
      }

      try {
        const attribute = node.added.element;

        const tag = this.attributeTagsMapped.find((tag) =>
          tag.items.find((el) => el.id === attribute.id),
        );

        const specs = [
          this.groupId,
          attribute.id,
          {
            tags: [tag.id],
          },
        ];

        await this.$api.groupUserAttributes.update(...specs);
        await this.atttributeListSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    formatOptionLabel(option) {
      if (!option.price) {
        return option.name;
      }

      return `${option.name} (${this.formatPrice(option.price)})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.attribute-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.relation__icon {
  color: $accent;
}

.sortable-ghost {
  opacity: 0;
}

.draggable-handle.disabled {
  color: grey !important;
  pointer-events: none;
}

.loading {
  pointer-events: none;
  opacity: 0.5;
}

.tag-controls {
  opacity: 0;
}

.v-expansion-panel__header:hover .tag-controls {
  opacity: 1;
}
</style>
