<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      width="820"
      persistent
    >
      <v-card
        v-if="dialog"
        class="border-radius"
      >
        <v-card-actions>
          <v-spacer />
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-card-actions>
        <div class="mb-3 sw-h4 text-center">
          {{ $t("create-your-question") }}
        </div>
        <v-card-text class="pt-0">
          <div class="pb-2">
            <v-form data-vv-scope="signup-attribute">
              <v-layout class="row wrap mb-3">
                <v-flex xs12>
                  <v-text-field
                    v-model="model.name"
                    :error-messages="errors.collect('signup-attribute.name')"
                    v-validate.disable="'required|max:150'"
                    data-vv-name="name"
                    :label="$t('what-question-do-you-want-to-ask')"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="model.description"
                    :label="$t('signup_wizard.internal_description_label')"
                  />
                </v-flex>
                <v-flex
                  xs12
                  class="mb-3"
                >
                  <QuillEditor
                    :value="attributeDescription"
                    @input="attributeDescription = $event"
                    :config="editorConfig"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="attributeType"
                    :items="attributeTypes"
                    :error-messages="errors.collect('signup-attribute.type')"
                    v-validate.disable="'required'"
                    data-vv-name="type"
                    item-text="name"
                    item-value="id"
                    return-object
                    :label="$t('select-type-of-answer')"
                    :disabled="model && model.id ? true : false"
                  >
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <v-layout class="row slot-item align-center">
                        <v-flex class="xs6">
                          <span class="body-2">{{ data.item.name }}</span>
                        </v-flex>
                        <v-flex class="xs6 text-right">
                          <span class="sw-caption text-capitalize">{{
                            data.item.description
                          }}</span>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  v-if="attributeType && attributeType.type === 'options'"
                  xs12
                >
                  <v-select
                    v-model="attributeDisplayType"
                    :items="displayTypes"
                    item-text="name"
                    item-value="value"
                    :label="$t('signup_wizard.display_type_label')"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12>
                  <template
                    v-if="attributeType && attributeType.type === 'options'"
                  >
                    <AttributesOptions
                      v-model="model"
                      :display-type="attributeDisplayType"
                    />
                  </template>
                </v-flex>
              </v-layout>
            </v-form>
          </div>
        </v-card-text>
        <div class="pa-3 text-xs-center">
          <v-btn
            v-if="model.id"
            round
            class="white sw-primary text-none"
            @click="disableUserAttribute(model.id)"
            :loading="isLoading"
            >{{ $t("common.delete") }}</v-btn
          >
          <v-btn
            round
            class="white sw-primary text-none"
            @click="dialog = false"
            >{{ $t("common.cancel") }}</v-btn
          >
          <v-btn
            v-if="!model.id"
            round
            class="sw-accent-bg sw-on-accent text-none"
            @click="createUserAttribute"
            :loading="isLoading"
            >{{ $t("common.create") }}</v-btn
          >
          <v-btn
            v-if="model.id"
            round
            class="sw-accent-bg sw-on-accent text-none"
            @click="updateUserAttribute"
            :loading="isLoading"
            >{{ $t("common.save") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <ConfirmationDialog ref="confirmationDialog" />
  </div>
</template>

<script>
import AttributesOptions from "@/components/SignupWizard/SignupQuestions/AttributesOptions.vue";

export default {
  name: "AttributesForm",
  inject: ["parentValidator"],
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
    groupPluginId: {
      type: [String, Number],
      required: true,
    },
    categoryId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    model: {
      options: [],
    },
    datetimeFormat: "",
    attributeType: null,
    attributeDisplayType: "",
    attributeDescription: "",
    option: {},
  }),
  computed: {
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    displayTypes() {
      return [
        {
          name: this.$t("signup_wizard.display_type_default_label"),
          value: "",
        },
        {
          name: this.$t("signup_wizard.display_type_customized_label"),
          value: "customized",
        },
      ];
    },
    attributeTypes() {
      return [
        {
          id: 1,
          name: this.$t("varchar-label"),
          description: this.$t("varcharDescription"),
          type: "varchar",
          details: {
            display_type: "",
          },
          is_collection: false,
        },
        {
          id: 2,
          name: this.$t("text-label"),
          description: this.$t("textDescription"),
          type: "text",
          details: {
            display_type: "",
          },
          is_collection: false,
        },
        {
          id: 3,
          name: this.$t("single-select-label"),
          description: "",
          type: "options",
          details: {
            display_type: "",
          },
          is_collection: false,
        },
        {
          id: 4,
          name: this.$t("multi-select-label"),
          description: "",
          type: "options",
          details: {
            display_type: "",
          },
          is_collection: true,
        },
        {
          id: 5,
          name: this.$t("date-picker", {
            format: this.datetimeFormat,
          }),
          description: "",
          type: "datetime",
          details: {
            display_type: "",
          },
          is_collection: false,
        },
      ];
    },
    editorConfig() {
      return {
        label: this.$t("signup_wizard.description_label"),
        style: {
          height: "150px",
        },
      };
    },
  },
  components: {
    AttributesOptions,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    // Called from parent component
    showAttributeDialog(attributeTagId, attributeId) {
      this.errors.clear();

      this.model = {
        options: [],
      };

      if (attributeTagId) {
        this.model.tags = [attributeTagId];
      }

      this.attributeType = null;
      this.attributeDisplayType = "";
      this.attributeDescription = "";

      if (attributeId) {
        this.getGroupUserAttribute(attributeId);
      }

      this.dialog = true;
    },
    transformPriceForSave(price) {
      if (!price) {
        return null;
      }
      return Number(price) * 100;
    },
    transformPriceForEdit(price) {
      if (!price) {
        return null;
      }
      return (Number(price) / 100).toFixed(2);
    },
    transformOptionsForSave(options) {
      if (!options) return;

      return options.map((option, i) => ({
        id: option.id,
        name: option.name,
        body: option.body,
        value: option.name,
        usage_limit: option.usage_limit,
        price: this.transformPriceForSave(option.price),
        vat: option.vat,
        sort_order: i,
      }));
    },
    transformOptionsForEdit(options) {
      if (!options) return;

      return options.map((option) => ({
        id: option.id,
        name: option.name,
        body: option.body || "",
        value: option.name,
        usage_limit: option.usage_limit,
        price: this.transformPriceForEdit(option.price),
        vat: option.vat,
        editable: true,
      }));
    },
    async getGroupUserAttribute(attributeId) {
      if (!attributeId) return;

      try {
        const params = [
          this.groupId,
          attributeId,
          { lang: this.appContentLanguage },
        ];

        this.isLoading = true;

        const response = await this.$api.groupUserAttributes.get(...params);

        this.isLoading = false;

        const attribute = response.data.data || {};

        this.model = {
          id: attribute.id,
          name: attribute.name,
          description: attribute.description,
          type: attribute.type,
          options:
            attribute.options && attribute.options.length
              ? this.transformOptionsForEdit(attribute.options)
              : [],
          is_collection: attribute.is_collection,
          tags: attribute.tags.map((tag) => tag.id),
          enabled: attribute.enabled,
        };

        this.attributeType = this.attributeTypes.find(
          (attrType) =>
            attrType.type === attribute.type &&
            attrType.is_collection === attribute.is_collection,
        );

        const details = attribute.details ? JSON.parse(attribute.details) : {};

        this.attributeDisplayType = details.display_type || "";
        this.attributeDescription = details.description || "";
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async createUserAttribute() {
      const isValid = await this.$validator.validateAll("signup-attribute");

      if (!isValid) return null;

      try {
        const specs = [
          this.groupId,
          {
            name: this.model.name,
            description: this.model.description,
            categories: this.categoryId,
            type: this.attributeType.type,
            details: JSON.stringify({
              display_type: this.attributeDisplayType,
              description: this.attributeDescription || "",
            }),
            is_collection: this.attributeType.is_collection ? 1 : 0,
            options:
              this.attributeType.type === "options"
                ? this.transformOptionsForSave(this.model.options)
                : [],
            tags: this.model.tags,
            enabled: 1,
            group_plugin_id: this.groupPluginId,
            lang: this.appContentLanguage,
          },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributes.create(...specs);

        this.isLoading = false;
        this.dialog = false;

        this.$emit("attribute-created");
        this.$store.dispatch("addNotification", {
          message: this.$t("question_created_successfully"),
        });
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async updateUserAttribute() {
      const isValid = await this.$validator.validateAll("signup-attribute");

      if (!isValid) return null;

      try {
        const specs = [
          this.groupId,
          this.model.id,
          {
            name: this.model.name,
            description: this.model.description,
            categories: this.categoryId,
            details: JSON.stringify({
              display_type: this.attributeDisplayType,
              description: this.attributeDescription || "",
            }),
            options:
              this.model.type === "options"
                ? this.transformOptionsForSave(this.model.options)
                : [],
            tags: this.model.tags,
            lang: this.appContentLanguage,
          },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributes.update(...specs);

        this.isLoading = false;

        this.dialog = false;
        this.$emit("attribute-updated");
        this.$store.dispatch("addNotification", {
          message: this.$t("question_updated_successfully"),
        });
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async disableUserAttribute(attributeId) {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("signup_wizard.questions_delete_warning_message"),
      );

      if (!isConfirmed || !attributeId) return;

      try {
        const specs = [
          this.groupId,
          attributeId,
          {
            enabled: 0,
          },
        ];

        this.isLoading = true;

        await this.$api.groupUserAttributes.update(...specs);

        this.isLoading = false;

        this.dialog = false;

        this.$emit("attribute-updated");
        this.$store.dispatch("addNotification", {
          message: this.$t("question_deleted_successfully"),
        });
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async getDateTimeFormat() {
      try {
        const response = await this.$api.settings.get("global.date_format");
        this.datetimeFormat = response.data.data.value;
      } catch (error) {}
    },
  },
  watch: {
    attributeType(val) {
      if (!this.model.id && val && val.type === "options") {
        this.model.options = [];
      }
    },
  },
  mounted() {
    this.getDateTimeFormat();
  },
};
</script>

<style scoped>
.zeropad /deep/ .v-toolbar__content {
  padding: 0;
}
</style>
