<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="820"
    persistent
  >
    <v-card
      v-if="dialog"
      class="border-radius"
    >
      <v-card-actions>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon small>close</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="mb-3 sw-h4 text-center">
        {{ option.name || "Option name" }}
      </div>
      <v-card-text class="pa-3">
        <v-form data-vv-scope="signup-attribute-option">
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <div class="font-weight-bold">
                {{ $tc("signup_wizard.questions_option_name_label", 1) }}
              </div>
              <v-text-field
                v-model="option.name"
                :error-messages="errors.collect('signup-attribute-option.name')"
                v-validate.disable="'required|max:150'"
                data-vv-name="name"
                :data-vv-as="
                  $tc('signup_wizard.questions_option_name_label', 2)
                "
                :label="$t('signup_wizard.questions_option_name_placeholder')"
              />
            </v-flex>
            <v-flex
              xs12
              mb-2
            >
              <v-layout>
                <v-flex
                  xs6
                  pr-2
                >
                  <div class="font-weight-bold">
                    {{
                      $tc("signup_wizard.questions_option_quantity_label", 1)
                    }}
                  </div>
                  <v-text-field
                    v-model="option.usage_limit"
                    :error-messages="errors.collect('usage_limit')"
                    v-validate.disable="'numeric'"
                    data-vv-name="usage_limit"
                    :data-vv-as="
                      $tc('signup_wizard.questions_option_quantity_label', 2)
                    "
                    :label="
                      $t('signup_wizard.questions_option_quantity_placeholder')
                    "
                  />
                </v-flex>
                <v-flex
                  xs6
                  pl-2
                >
                  <div class="font-weight-bold">
                    <TranslatableTooltip :content="priceTooltip">
                      {{ priceLabel }}
                    </TranslatableTooltip>
                  </div>
                  <v-text-field
                    v-model="option.price"
                    :error-messages="
                      errors.collect('signup-attribute-option.price')
                    "
                    v-validate.disable="'price'"
                    data-vv-name="price"
                    :data-vv-as="
                      $t('signup_wizard.questions_option_price_data_vv_as')
                    "
                    :label="
                      $t('signup_wizard.questions_option_price_placeholder')
                    "
                    :suffix="groupCurrency"
                    @blur="option.price = formatPrice(option.price)"
                    :disabled="!isPaymentsEnabled"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              xs12
              mb-3
            >
              <div class="font-weight-bold">
                <tooltip :name="textTooltip">
                  {{ $t("signup_wizard.questions_option_text_label") }}
                </tooltip>
              </div>
            </v-flex>
            <v-flex
              xs12
              mb-4
              :class="{ disabled: displayType !== 'customized' }"
            >
              <QuillEditor
                :value="option.body"
                @input="option.body = $event"
                :config="editorConfig"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-layout>
        <v-flex
          xs12
          class="pa-3 text-xs-center"
        >
          <v-btn
            round
            class="white sw-primary text-none"
            @click="dialog = false"
            >{{ $t("common.cancel") }}</v-btn
          >
          <v-btn
            round
            class="sw-accent-bg sw-on-accent text-none"
            @click="save()"
            >{{ $t("common.apply") }}</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OptionModal",
  props: {
    displayType: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    dialog: false,
    optionIndex: -1,
    option: {},
  }),
  computed: {
    activeGroup() {
      return this.$store.getters.activeGroup || {};
    },
    groupCurrency() {
      if (!this.activeGroup.currency) {
        return "";
      }

      return this.activeGroup.currency;
    },
    platformFeeIncluded() {
      return this.activeGroup.platform_fee_included;
    },
    isPaymentsEnabled() {
      return this.activeGroup.payments_enabled;
    },
    priceLabel() {
      return this.$t("signup_wizard.questions_option_price_label");
    },
    priceTooltip() {
      if (!this.isPaymentsEnabled) {
        return this.$t(
          "signup_wizard.questions_option_price_label_disabled_payments_tooltip",
        );
      }

      return "";
    },
    textTooltip() {
      return this.displayType !== "customized"
        ? "SignupWizardQuestionsOptionsText"
        : "";
    },
    editorConfig() {
      return {
        label: "",
      };
    },
  },
  methods: {
    open(optionIndex, option) {
      this.optionIndex = optionIndex;
      this.option = { ...option };
      this.dialog = true;
    },
    async save() {
      const isValid = await this.$validator.validateAll(
        "signup-attribute-option",
      );

      if (!isValid) return;

      this.$emit("update", this.optionIndex, this.option);

      this.dialog = false;
    },
    formatPrice(price) {
      if (!price || isNaN(Number(price))) {
        return "0.00";
      }

      return Number(price).toFixed(2);
    },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
